
import { ActionSheet, Popup, Icon } from "vant";
import { defineComponent, ref } from "vue";
import { useRoute } from 'vue-router'
import Temps from "./temp";
interface childObj {
  name?: string;
  id: number;
}
interface tabMap {
  icon: string;
  name: string;
  id: number;
  children: childObj[];
}
export default defineComponent({
  components: {
    vanActionSheet: ActionSheet,
    vanPopup: Popup,
    vanIcon: Icon,
    ...Temps.footBall,
    ...Temps.other,
  },
  setup() {
    const title = ref<string | undefined>("");
    const show = ref(false);
    const isDetail = ref(false);
    const pageList = ref<childObj[]>([]);
    const componentId = ref<number>(1);
    const parentId = ref(1); // 大类ID
    const pName = ref("");
    const route = useRoute()
    const tabs = ref<tabMap[]>([
      { icon: "", name: "一般体育说明", id: 9, children: [] },
      { icon: "", name: "冠军投注", id: 10, children: [] },
      {
        icon: "",
        name: "足球",
        id: 1,
        children: [
          { name: "一般规则", id: 0 },
          { name: "让球盘（亚洲让球盘）", id: 1 },
          { name: "大小盘", id: 2 },
          { name: "1x2(独赢盘)", id: 3 },
          { name: "总进球：单双", id: 4 },
          { name: "波胆", id: 5 },
          { name: "全场/半场", id: 6 },
          { name: "双赢盘", id: 7 },
          { name: "总入球数", id: 8 },
          { name: "总入球数-全场", id: 9 },
          { name: "总入球数-半场", id: 10 },
          { name: "最先/最后进球球队", id: 11 },
          { name: "滚球/下一支球球队", id: 12 },
          { name: "角球：一般规则", id: 13 },
          { name: "角球：让球盘(亚洲盘口)", id: 14 },
          { name: "半场角球：让球盘(亚洲盘口)", id: 15 },
          { name: "总角球：独赢盘", id: 16 },
          { name: "半场角球：独赢盘", id: 17 },
          { name: "总角球：单双盘", id: 18 },
          { name: "半场角球：单双盘", id: 19 },
          { name: "第一个角球/最后一个角球", id: 20 },
          { name: "半场最多角球数", id: 21 },
          { name: "上半场角球：独赢盘", id: 22 },
          { name: "下半场角球：独赢盘", id: 23 },
          { name: "首个角球时间 ", id: 24 },
          { name: "点球惩罚", id: 25 },
          { name: "点球：让球盘", id: 26 },
          { name: "点球：大小盘", id: 27 },
          { name: "联赛：联赛一般规则", id: 28 },
          { name: "小组赛", id: 29 },
          { name: "排名前（4,6,10）等", id: 30 },
          { name: "没有球队的X的联赛冠军", id: 31 },
          { name: "联赛：最后一名球队", id: 32 },
          { name: "联赛：被降级的球队球队", id: 33 },
          { name: "联赛：球队保持原位", id: 34 },
          { name: "联赛：球队晋级", id: 35 },
          { name: "联赛：最佳新秀", id: 36 },
          { name: "比赛-进球最多的球队", id: 37 },
          { name: "比赛-失球最多的球队", id: 38 },
          { name: "比赛-总进球数", id: 39 },
          { name: "比赛-帽子戏法", id: 40 },
          { name: "比赛-总红卡数", id: 41 },
          { name: "比赛-总黄卡数", id: 42 },
          { name: "比赛-进球最多的城市", id: 43 },
          { name: "比赛-获胜小组", id: 44 },
          { name: "比赛-小组最后一名球队", id: 45 },
          { name: "冠军所属地", id: 46 },
          { name: "比赛-晋级", id: 47 },
          { name: "比赛-阶段淘汰", id: 48 },
          { name: "比赛-提名入围", id: 49 },
          { name: "比赛-最终裁判员", id: 50 },
          { name: "直接预测排名 （联赛、比赛）", id: 51 },
          { name: "双预测排名", id: 52 },
          { name: "最佳射手", id: 53 },
          { name: "最佳射手球队", id: 54 },
          { name: "最佳射手/比赛双赢", id: 55 },
          { name: "进球最多的小组", id: 56 },
          { name: "特定联赛里主客对的总进球数", id: 57 },
          { name: "特定联赛里主客对的总进球数:一般规则", id: 58 },
          { name: "特定联赛里主客对的总进球数：让球盘", id: 59 },
          { name: "特定联赛里主客对的总进球数：大小盘", id: 60 },
          { name: "反波胆", id: 61 },
          { name: "和球比分", id: 62 },
          { name: "双方皆得分", id: 63 },
          { name: "零失球获胜", id: 64 },
          { name: "零失球", id: 65 },
          { name: "赢得所有半场", id: 66 },
          { name: "赢得任意一半场", id: 67 },
          { name: "上/下半场皆得分", id: 68 },
          { name: "三项让分投注", id: 69 },
          { name: "三项大小投注", id: 70 },
          { name: "双重波胆", id: 71 },
          { name: "净胜球数", id: 72 },
          { name: "球队总进球数", id: 73 },
          { name: "单一球队大/小", id: 74 },
          { name: "全场独赢&单双", id: 75 },
          { name: "全场独赢&双方皆得分", id: 76 },
          { name: "全场独赢&大小", id: 77 },
        ],
      },
      { icon: "", name: "篮球", id: 2, children: [] },
      { icon: "", name: "网球", id: 4, children: [] },
      { icon: "", name: "排球", id: 3, children: [] },
      { icon: "", name: "乒乓球", id: 7, children: [] },
      { icon: "", name: "羽毛球", id: 8, children: [] },
      { icon: "", name: "桌球", id: 14, children: [] },
      // { icon: "", name: "高尔夫球", id: 12, children: [] },
      // { icon: "", name: "手球", id: 11, children: [] },
    ]);
    const selectItem = (item: childObj) => {
      componentId.value = item.id;
      isDetail.value = true;
      title.value = pName.value + "  " + item.name;
    };
    const showSportMenuOrdetails = (
      id: number,
      name: string,
      children: childObj[]
    ) => {
      pageList.value = children;
      parentId.value = id;
      if (children.length) {
        title.value = name;
        pName.value = name;
      } else {
        componentId.value = id;
        title.value = name;
      }
      // 展示弹窗
      show.value = children.length > 0;
      isDetail.value = children.length == 0;
    };
    return {
      tabs,
      title,
      parentId,
      pName,
      componentId,
      show,
      isDetail,
      pageList,
      route,
      showSportMenuOrdetails,
      selectItem,
    };
  },
});
