// 导入足球modules 下的所有文件
const footBallModulesFiles = require.context(
  "@/mobile-rules/footBall",
  true,
  /\.vue$/
);
const otherModulesFiles = require.context(
  "@/mobile-rules/other",
  true,
  /\.vue$/
);

const getBallTemp = (obj: any) => {
  const Models = obj.keys().map((v: any) => {
    return obj(v).default;
  });
  interface objValue {
    [key: string]: any;
  }
  const tempMap: objValue = {};

  Models.forEach((t: any) => {
    tempMap[t.name] = t;
  });
  return tempMap;
};

export default {
  footBall: getBallTemp(footBallModulesFiles),
  other: getBallTemp(otherModulesFiles),
};
